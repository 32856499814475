
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0" style="position: relative;">
      <img src="@/assets/images/banner/idp3.jpg" class="background-image"/>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
      </b-col>

      <!-- Login -->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5" v-if="state == 1">
        <b-col sm="8" md="12" lg="12" class="px-xl-2 mx-auto">
          <img src="@/assets/images/logo/logo-police.png" style="width:300px;display:block;margin:0 auto" class="img-title"/>
          <b-card-title title-tag="h2" class="font-weight-bold mb-1 text-center">
            Mobile Data Terminal
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            Silahkan Login dengan akun yang sudah terdaftar
          </b-card-text>
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="submitLogin" v-if="!isLoading">
              <b-form-group label="Police ID" label-for="police-id">
                <validation-provider #default="{ errors }" name="Police ID" rules="required">
                  <b-form-input :disabled="isLoading" id="police-id" v-model="loginForm.username" :state="errors.length > 0 ? false : null" name="login-email" placeholder="Police ID"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group >
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input :disabled="isLoading" id="login-password" v-model="loginForm.password" :state="errors.length > 0 ? false : null" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="············"/>
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility"/>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button v-if="!isLoading" type="submit" variant="primary" block>Login</b-button>
            </b-form>
            <loading v-if="isLoading"></loading>
            <p style="color:red; text-align:center; margin-top:20px;">{{failedMessage}}</p>
          </validation-observer>
        </b-col>
      </b-col>

      <!-- Email -->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5" v-if="state == 2">
        <b-col sm="8" md="12" lg="12" class="px-xl-2 mx-auto">
          <img src="@/assets/images/logo/logo-police.png" style="width:300px;display:block;margin:0 auto" class="img-title"/>
          <b-card-title title-tag="h2" class="font-weight-bold mb-1 text-center">
            Mobile Data Terminal
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            Silahkan Masukan Email
          </b-card-text>
          <validation-observer ref="emailForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="submitEmail" v-if="!isLoading">
              <b-form-group label="Alamat Email" label-for="police-id">
                <validation-provider #default="{ errors }" name="login-email" rules="required|email">
                  <b-form-input :disabled="isLoading" id="police-id" v-model="loginForm.email" :state="errors.length > 0 ? false : null" name="login-email" placeholder="alamat email"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button v-if="!isLoading" type="submit" variant="warning" block>Kirim OTP</b-button>
            </b-form>
            <loading v-if="isLoading"></loading>
            <p style="color:red; text-align:center; margin-top:20px;">{{failedMessage}}</p>
          </validation-observer>
        </b-col>
      </b-col>

      <!-- Otp -->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5" v-if="state == 3">
        <b-col sm="8" md="12" lg="12" class="px-xl-2 mx-auto">
          <img src="@/assets/images/logo/logo-police.png" style="width:300px;display:block;margin:0 auto" class="img-title"/>
          <b-card-title title-tag="h2" class="font-weight-bold mb-1 text-center">
            Mobile Data Terminal
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            Silahkan Masukan Kode OTP
          </b-card-text>
          <validation-observer ref="otpForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="submitOtp" v-if="!isLoading">
              <b-form-group label="Kode OTP" label-for="police-id">
                <validation-provider #default="{ errors }" name="login-otp" rules="required|min:5">
                  <b-form-input :disabled="isLoading" id="police-id" v-model="loginForm.otp" :state="errors.length > 0 ? false : null" name="login-otp" placeholder="5 Digit Kode OTP"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button v-if="!isLoading" type="submit" variant="success" block>Login</b-button>
            </b-form>
            <loading v-if="isLoading"></loading>
            <p style="color:red; text-align:center; margin-top:20px;">{{failedMessage}}</p>
          </validation-observer>
          <p v-if="!isLoading" style="text-align:right; margin-top:5px; cursor:pointer;" @click="statechange">Kembali ke Login</p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Loading from '@/templates/Loading.vue'
import interfaces from "@/templates/_interfaces.js";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    Multiselect
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      refreshInterval:null,
      isLoading: false,
      loginForm: interfaces.login,
      registerForm: interfaces.register,
      state: 1, // 1 login // 2 masukin email buat otp // 3 otp
      failedMessage: "",
      dataPolisi: [],
      dataRole: [],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {
    // Jika sudah login, redirect ke dashboard
    if (localStorage.getItem("token")) {
      this.$router.push({ path: "/idp-dashboard" });
    }

    // Refresh otomatis setiap 30 detik
    this.refreshInterval = setInterval(() => {
      if (document.readyState === "complete") {
        window.location.reload();
      }
    }, 120000);
  },
  beforeDestroy() {
    // Bersihkan interval saat komponen dihancurkan
    clearInterval(this.refreshInterval);
  },
  methods: {
    init(){

    },
    submitLogin() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          setTimeout(() => {
            axios
              .post("authentication/login", this.loginForm)
              .then((response) => {
                if (response.data.status === 2000) {
                  if(response.data.next_state){
                    this.state = response.data.next_state
                    this.loginForm.email = response.data.email
                  }else if(response.data.identifier != null){
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("username", response.data.username);
                    localStorage.setItem("identifier", response.data.identifier);
                    localStorage.setItem("user_login_name", response.data.user_login_name);
                    localStorage.setItem("grade", response.data.grade);
                    localStorage.setItem("badge", response.data.badge);
                    localStorage.setItem("role", response.data.role);
                    this.handleSubmit();
                  }
                  if(this.state == 3){
                    this.loginForm.otp = null;
                    this.$swal({
                      title: "Check Inbox",
                      icon: "warning",
                      background: "black",
                      confirmButtonColor: "#7367f0",
                      confirmButtonText: "Close",
                    });
                  }
                } else if(response.data.status == 4000) {
                  this.isLoading = false;
                  this.failedMessage = "Username Dan Password Salah";
                  this.$swal({
                    title: "Username Password Salah",
                    icon: "warning",
                    background: "black",
                    confirmButtonColor: "#7367f0",
                    confirmButtonText: "Close",
                  });
                } else if(response.data.status == 3000) {
                  this.$swal({
                    title: "Anda Tidak Terdeteksi di Kota",
                    icon: "warning",
                    background: "black",
                    confirmButtonColor: "#7367f0",
                    confirmButtonText: "Close",
                  });
                } else {
                  this.$swal({
                    title: response.data.message,
                    icon: "warning",
                    background: "black",
                    confirmButtonColor: "#7367f0",
                    confirmButtonText: "Close",
                  });
                }
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Login Failed",
                    icon: "EditIcon",
                    variant: "danger",
                    text: error.message,
                  },
                });
                localStorage.clear();
                this.$router.push({ name: "login" });
              })
              .finally(() => {
                this.isLoading = false;
              });
          }, 1000);

        }
      });
    },
    submitEmail() {
      this.$refs.emailForm.validate().then((success) => {
        if (success) {
          this.isLoading = true;
            axios
            .post("authentication/email", this.loginForm)
            .then((response) => {
              if (response.data.status === 2000) {
                this.state = response.data.next_state
                this.isLoading = false
                this.loginForm.otp = null;
                this.$swal({
                  title: "Check Email",
                  icon: "success",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
              } else if(response.data.status == 4000) {
                this.isLoading = false;
                this.failedMessage = "Invalid Email";
                this.$swal({
                  title: "Invalid Email",
                  icon: "warning",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
              } else if(response.data.status == 3000) {
                this.$swal({
                  title: "Anda Tidak Terdeteksi di Kota",
                  icon: "warning",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
              } else {
                this.$swal({
                  title: response.data.message,
                  icon: "warning",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Login Failed",
                  icon: "EditIcon",
                  variant: "danger",
                  text: error.message,
                },
              });
              localStorage.clear();
              this.$router.push({ name: "login" });
            })
            .finally(() => {
              this.isLoading = false;
            });

        }
      });
    },
    submitOtp() {
      this.$refs.otpForm.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          setTimeout(() => {
            axios
              .post("authentication/loginotp", this.loginForm)
              .then((response) => {
                if (response.data.status === 2000) {
                  localStorage.setItem("token", response.data.token);
                  localStorage.setItem("username", response.data.username);
                  localStorage.setItem("identifier", response.data.identifier);
                  localStorage.setItem("user_login_name", response.data.user_login_name);
                  localStorage.setItem("grade", response.data.grade);
                  localStorage.setItem("badge", response.data.badge);
                  localStorage.setItem("role", response.data.role);
                  this.handleSubmit();
                } else if(response.data.status == 4000) {
                  this.isLoading = false;
                  this.failedMessage = response.data.message;
                  this.$swal({
                    title: response.data.message,
                    icon: "warning",
                    background: "black",
                    confirmButtonColor: "#7367f0",
                    confirmButtonText: "Close",
                  });
                } else if(response.data.status == 3000) {
                  this.$swal({
                    title: "Anda Tidak Terdeteksi di Kota",
                    icon: "warning",
                    background: "black",
                    confirmButtonColor: "#7367f0",
                    confirmButtonText: "Close",
                  });
                } else {
                  this.$swal({
                    title: response.data.message,
                    icon: "warning",
                    background: "black",
                    confirmButtonColor: "#7367f0",
                    confirmButtonText: "Close",
                  });
                }
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Login Failed",
                    icon: "EditIcon",
                    variant: "danger",
                    text: error.message,
                  },
                });
                localStorage.clear();
                this.$router.push({ name: "login" });
              })
              .finally(() => {
                this.isLoading = false;
              });
          }, 1000);

        }
      });
    },
    submitRegister() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {

          this.isLoading = true;
          setTimeout(() => {
            axios
              .post("authentication/register", this.registerForm)
              .then((response) => {
                if (response.data.status === 2000) {
                  this.$swal({
                    title: response.data.message,
                    icon: "success",
                    background: "black",
                    confirmButtonColor: "#7367f0",
                    confirmButtonText: "Close",
                  });
                } else if(response.data.status == 4000) {
                  this.$swal({
                    title: response.data.message,
                    icon: "warning",
                    background: "black",
                    confirmButtonColor: "#7367f0",
                    confirmButtonText: "Close",
                  });
                }
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Register Failed",
                    icon: "EditIcon",
                    variant: "danger",
                    text: error.message,
                  },
                });
                localStorage.clear();
                this.$router.push({ name: "login" });
              })
              .finally(() => {
                this.isLoading = false;
                this.state = 1;
              });
          }, 1000);

        }
      });
    },
    handleSubmit() {
      this.isLoading = false;
      this.$router.push({ path: "/idp-dashboard" });
    },
    getDropdown(){
      axios.get("cron/clearence-dpo").then((response) => {
        this.dataPolisi = response.data.police
        this.dataRole = response.data.role
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      });
    },
    statechange(){
      if(this.state == 1){
        this.state = 2;
        this.getDropdown();
      }else{
        this.state = 1
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover; /* Meregangkan gambar untuk mengisi seluruh ruang */
  background-position: center;
  opacity: 0.2; /* Opacity sesuaikan dengan kebutuhan */
}


</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>