var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0",staticStyle:{"position":"relative"}},[_c('img',{staticClass:"background-image",attrs:{"src":require("@/assets/images/banner/idp3.jpg")}}),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}}),(_vm.state == 1)?_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"12","lg":"12"}},[_c('img',{staticClass:"img-title",staticStyle:{"width":"300px","display":"block","margin":"0 auto"},attrs:{"src":require("@/assets/images/logo/logo-police.png")}}),_c('b-card-title',{staticClass:"font-weight-bold mb-1 text-center",attrs:{"title-tag":"h2"}},[_vm._v(" Mobile Data Terminal ")]),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" Silahkan Login dengan akun yang sudah terdaftar ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isLoading)?_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.submitLogin($event)}}},[_c('b-form-group',{attrs:{"label":"Police ID","label-for":"police-id"}},[_c('validation-provider',{attrs:{"name":"Police ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isLoading,"id":"police-id","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"Police ID"},model:{value:(_vm.loginForm.username),callback:function ($$v) {_vm.$set(_vm.loginForm, "username", $$v)},expression:"loginForm.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")])]),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"disabled":_vm.isLoading,"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.loginForm.password),callback:function ($$v) {_vm.$set(_vm.loginForm, "password", $$v)},expression:"loginForm.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.isLoading)?_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v("Login")]):_vm._e()],1):_vm._e(),(_vm.isLoading)?_c('loading'):_vm._e(),_c('p',{staticStyle:{"color":"red","text-align":"center","margin-top":"20px"}},[_vm._v(_vm._s(_vm.failedMessage))])]}}],null,false,1196647214)})],1)],1):_vm._e(),(_vm.state == 2)?_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"12","lg":"12"}},[_c('img',{staticClass:"img-title",staticStyle:{"width":"300px","display":"block","margin":"0 auto"},attrs:{"src":require("@/assets/images/logo/logo-police.png")}}),_c('b-card-title',{staticClass:"font-weight-bold mb-1 text-center",attrs:{"title-tag":"h2"}},[_vm._v(" Mobile Data Terminal ")]),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" Silahkan Masukan Email ")]),_c('validation-observer',{ref:"emailForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isLoading)?_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.submitEmail($event)}}},[_c('b-form-group',{attrs:{"label":"Alamat Email","label-for":"police-id"}},[_c('validation-provider',{attrs:{"name":"login-email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isLoading,"id":"police-id","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"alamat email"},model:{value:(_vm.loginForm.email),callback:function ($$v) {_vm.$set(_vm.loginForm, "email", $$v)},expression:"loginForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.isLoading)?_c('b-button',{attrs:{"type":"submit","variant":"warning","block":""}},[_vm._v("Kirim OTP")]):_vm._e()],1):_vm._e(),(_vm.isLoading)?_c('loading'):_vm._e(),_c('p',{staticStyle:{"color":"red","text-align":"center","margin-top":"20px"}},[_vm._v(_vm._s(_vm.failedMessage))])]}}],null,false,1391919825)})],1)],1):_vm._e(),(_vm.state == 3)?_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"12","lg":"12"}},[_c('img',{staticClass:"img-title",staticStyle:{"width":"300px","display":"block","margin":"0 auto"},attrs:{"src":require("@/assets/images/logo/logo-police.png")}}),_c('b-card-title',{staticClass:"font-weight-bold mb-1 text-center",attrs:{"title-tag":"h2"}},[_vm._v(" Mobile Data Terminal ")]),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" Silahkan Masukan Kode OTP ")]),_c('validation-observer',{ref:"otpForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isLoading)?_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.submitOtp($event)}}},[_c('b-form-group',{attrs:{"label":"Kode OTP","label-for":"police-id"}},[_c('validation-provider',{attrs:{"name":"login-otp","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isLoading,"id":"police-id","state":errors.length > 0 ? false : null,"name":"login-otp","placeholder":"5 Digit Kode OTP"},model:{value:(_vm.loginForm.otp),callback:function ($$v) {_vm.$set(_vm.loginForm, "otp", $$v)},expression:"loginForm.otp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.isLoading)?_c('b-button',{attrs:{"type":"submit","variant":"success","block":""}},[_vm._v("Login")]):_vm._e()],1):_vm._e(),(_vm.isLoading)?_c('loading'):_vm._e(),_c('p',{staticStyle:{"color":"red","text-align":"center","margin-top":"20px"}},[_vm._v(_vm._s(_vm.failedMessage))])]}}],null,false,2996242127)}),(!_vm.isLoading)?_c('p',{staticStyle:{"text-align":"right","margin-top":"5px","cursor":"pointer"},on:{"click":_vm.statechange}},[_vm._v("Kembali ke Login")]):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }